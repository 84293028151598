import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 539.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,539.000000) scale(0.100000,-0.100000)">
          <path d="M2930 1830 l0 -280 35 0 35 0 1 58 c1 54 3 59 41 92 l40 35 35 -40
c19 -22 53 -64 75 -92 38 -50 43 -53 85 -53 l45 0 -34 43 c-19 23 -61 75 -93
115 l-58 73 84 85 84 84 -45 0 c-43 -1 -50 -5 -150 -99 l-105 -99 -3 179 -2
179 -35 0 -35 0 0 -280z"/>
<path d="M4570 1998 c0 -62 -4 -108 -8 -102 -20 31 -79 54 -138 54 -53 0 -68
-5 -110 -32 -26 -18 -56 -50 -67 -70 -22 -43 -25 -127 -8 -181 37 -110 206
-160 298 -87 l28 22 3 -26 c3 -22 8 -26 38 -26 l34 0 0 280 0 280 -35 0 -35 0
0 -112z m-62 -128 c62 -38 81 -138 39 -201 -29 -42 -60 -59 -115 -59 -89 0
-138 57 -130 153 5 59 30 100 73 117 37 16 99 11 133 -10z"/>
<path d="M1060 2050 l0 -30 95 0 95 0 0 -170 c0 -219 -9 -240 -99 -240 -24 0
-52 9 -75 25 l-37 25 -20 -22 c-19 -23 -19 -23 14 -51 60 -52 166 -60 227 -17
54 39 60 67 60 299 l0 211 -130 0 -130 0 0 -30z"/>
<path d="M5257 2033 c-4 -3 -7 -24 -7 -45 0 -36 -2 -38 -30 -38 -27 0 -30 -3
-30 -30 0 -26 3 -30 29 -30 l28 0 5 -134 c5 -151 15 -177 73 -202 43 -18 67
-18 110 0 32 14 34 17 24 39 -10 23 -14 24 -46 15 -27 -7 -40 -6 -59 7 -23 15
-24 19 -24 145 l0 130 55 0 c54 0 55 0 55 30 0 30 -1 30 -55 30 l-55 0 0 45
c0 44 0 45 -33 45 -19 0 -37 -3 -40 -7z"/>
<path d="M1569 1949 c-86 -13 -130 -49 -95 -78 10 -8 22 -7 49 4 50 21 136 19
165 -3 13 -10 25 -33 29 -55 l6 -37 -84 -1 c-103 -1 -147 -16 -176 -59 -27
-39 -29 -70 -8 -110 33 -64 153 -87 228 -43 36 22 37 22 37 3 0 -16 7 -20 35
-20 l35 0 0 145 c0 125 -3 151 -20 184 -30 59 -105 85 -201 70z m151 -253 c0
-68 -67 -108 -153 -92 -63 12 -73 92 -14 116 12 5 55 9 95 9 l72 1 0 -34z"/>
<path d="M2107 1950 c-42 -8 -87 -29 -87 -42 0 -4 -4 -8 -10 -8 -5 0 -10 11
-10 25 0 22 -4 25 -35 25 l-35 0 0 -200 0 -200 35 0 35 0 0 115 c0 128 11 169
55 203 20 16 42 22 77 22 41 0 54 -5 79 -29 l29 -29 0 -141 0 -141 35 0 35 0
0 149 c0 137 -2 151 -23 181 -38 58 -105 84 -180 70z"/>
<path d="M3489 1950 c-55 -9 -109 -36 -109 -53 1 -34 17 -40 59 -23 48 19 139
21 162 3 21 -16 39 -51 39 -76 0 -19 -6 -21 -79 -21 -124 0 -184 -30 -197 -98
-9 -46 16 -96 58 -119 47 -24 136 -22 182 5 35 21 36 21 36 2 0 -16 7 -20 35
-20 l35 0 0 140 c0 188 -23 240 -110 256 -19 3 -42 7 -50 9 -8 1 -36 -1 -61
-5z m151 -251 c0 -17 -5 -39 -10 -50 -21 -39 -95 -59 -157 -43 -30 8 -48 55
-33 84 16 30 44 39 128 39 l72 1 0 -31z"/>
<path d="M4879 1949 c-48 -7 -109 -34 -109 -47 0 -5 6 -16 13 -26 12 -17 16
-17 51 -2 52 22 138 21 165 -1 12 -10 26 -34 30 -54 l8 -37 -102 -4 c-115 -4
-148 -18 -171 -73 -49 -119 106 -209 234 -134 41 24 42 24 42 -1 0 -16 7 -20
30 -20 l30 0 0 151 c0 135 -2 155 -20 184 -33 54 -110 79 -201 64z m151 -257
c0 -53 -33 -83 -100 -90 -76 -7 -119 30 -99 86 12 31 42 41 127 41 l72 1 0
-38z"/>
<path d="M5658 1949 c-85 -13 -122 -42 -99 -78 8 -12 15 -11 50 3 23 9 65 16
95 16 44 0 58 -4 80 -26 16 -17 26 -38 26 -55 l0 -29 -77 0 c-104 0 -147 -13
-178 -54 -42 -56 -25 -129 38 -163 46 -25 135 -22 182 5 44 27 44 27 38 2 -5
-17 0 -20 31 -20 l36 0 0 146 c0 131 -2 150 -22 185 -33 59 -104 83 -200 68z
m152 -250 c0 -63 -45 -99 -124 -99 -49 0 -72 16 -82 56 -13 50 29 72 134 73
l72 1 0 -31z"/>
<path d="M2522 1929 c-81 -41 -123 -161 -88 -254 36 -94 144 -148 252 -125 27
6 63 21 79 34 29 23 29 25 12 44 -19 20 -19 20 -52 1 -44 -26 -124 -27 -167
-2 -35 20 -73 76 -63 93 4 6 67 10 166 10 l159 0 -1 33 c-1 65 -33 120 -93
161 -51 34 -140 36 -204 5z m139 -39 c42 -12 75 -44 84 -82 l7 -28 -131 0
c-72 0 -131 2 -131 5 0 3 6 20 14 38 17 42 59 70 111 76 6 0 26 -4 46 -9z"/>
<path d="M3780 1941 c0 -4 27 -68 61 -142 107 -235 113 -253 95 -286 -8 -16
-22 -35 -30 -42 -16 -13 -75 -11 -107 3 -11 5 -19 -1 -28 -20 -6 -15 -10 -28
-9 -29 19 -16 56 -25 98 -25 85 0 115 31 183 195 13 33 54 127 90 208 l66 148
-35 -3 -36 -3 -65 -150 c-61 -143 -83 -184 -83 -157 0 6 -13 40 -29 74 -15 35
-45 102 -66 151 -36 85 -38 87 -71 87 -19 0 -34 -4 -34 -9z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
